<script setup lang="ts">
import { Times } from '@ui/icons/regular';
import IconButton from '../icons/IconButton.vue';
import Background from '../background/Background.vue';

withDefaults(defineProps<{
  label?: string;
  text?: string;
  full?: boolean;
  close?: boolean;
  type?: 'mini' | 'full';
  maxWidth?: string;
}>(), {
  label: '',
  text: '',
  full: true,
  close: true,
  type: 'mini',
  maxWidth: '',
})

defineSlots<{
  header: () => void;
  'header-bottom': () => void;
  content: () => void;
  actions: () => void;
}>();

const show = defineModel<boolean>('show', { default: false });
</script>

<template lang="pug">
Background(v-model:show="show")
  .modal-dialog.flex.col-0.w-100p(:class="type" :style="{'max-width': `min(${['calc(100dvw - 16px)', maxWidth].filter(Boolean).join(', ')})`}")
    .header-container.typ-14-20-500.flex.col-8.p-t-20(:class="{grey: $slots['header-bottom']}")
      .header.flex-between-center.p-x-24
        .header-label
          slot(name="header")
            | {{ $t(label) }}
        IconButton.size-18(v-if="close" :name="Times" @click="$emit('update:show', false)")
      slot(name="header-bottom")

    .content-container.flex.h-100p
      slot(name="content")
        | {{ text }}

    .actions-container.flex-between-center.gap-10.p-8-24-24(v-if="$slots.actions")
      slot(name="actions")
</template>

<style scoped lang="scss">
@import "@ui/styles/scss/colors";

.modal-dialog {
  overflow: hidden;
  border: 1px solid #E5E5E9;
  background: $color-white;
  box-shadow: 0 1px 20px 0 rgba(0, 0, 0, 0.20);
  min-width: 304px;
  max-height: calc(100dvh - 16px);

  &.mini {
    --uno: br-24;
  }
}

.header-container {
  &.grey {
    background: #F8F8F9;
  }
}

.content-container {
  overflow: auto;
}
</style>
<script setup lang="ts">
import { syncRef, useScrollLock } from '@vueuse/core';

withDefaults(defineProps<{
  closable?: boolean;
}>(), {
  closable: true,
})

const show = defineModel<boolean>('show', { default: false });

const el = computed(() => document?.body);
const isLocked = useScrollLock(el)
syncRef(show, isLocked, { direction: 'ltr' });
</script>

<template lang="pug">
teleport(to="body")
  .background.flex-center-center(v-if="show" @click.self="closable && $emit('update:show', false)")
    slot
</template>

<style scoped lang="scss">
.background {
  z-index: 200;
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: rgba(31, 31, 31, .5);
}
</style>